<template>
<div class="row">
  <div class="col-med">

    <CTabs variant="tabs" :active-tab="0">
      <CTab title="Create a service">
        <div class="tab-box" style="padding: 20px">
          <div>
            <!-- ----------------------------------------------------------------- -->
            <div v-if="!enteredServiceName">
              <h4>What would you like to name your service?</h4>
              <div style="height: 10px"></div>
              <input type="text" style="height: 35px; width: 300px" v-model="serviceName" v-on:keydown="typedServiceName">
              <CButton color="primary" @click="verifyServiceName()" style="margin: -3px 0px 0px 5px">
                Continue
              </CButton>
            </div>

            <!-- ----------------------------------------------------------------- -->
            <div v-else-if="!enteredCloudSQLConnection">
              <h4>Enter a CloudSQL connection name</h4>
              <div style="height: 10px"></div>
              <input type="text" style="height: 35px; width: 300px" v-model="cloudSQLConnection" v-on:keydown="typedCloudSQLConnection" placeholder="nrcc-2017:us-east4:shared">
              <CButton color="primary" @click="verifyCloudSQLConnection()" style="margin: -3px 0px 0px 5px">
                Continue
              </CButton>
              <div style="text-align: center; margin-top: 15px">

                <div v-if="isRefreshingCloudSQLInstances" class="spinner-border spinner-border-sm text-primary" role="status">
                  <span class="sr-only"></span>
                </div>
                <div v-else-if="cloudSQLInstances.length == 0">
                  No instances to display
                </div>
                <div v-else>
                  <div v-for="instance in cloudSQLInstances">
                    <CButton color="secondary" @click="selectCloudSQLConnection(instance)" style="width: 100%; margin-top: 5px">
                      {{ instance.connection }}
                    </CButton>
                  </div>
                </div>
              </div>
            </div>

            <!-- ----------------------------------------------------------------- -->
            <div v-else-if="!enteredCloudSQLDatabase">
              <h4>Enter a database name</h4>
              <div style="height: 10px"></div>
              <input type="text" style="height: 35px; width: 300px" v-model="cloudSQLDatabase" v-on:keydown="typedCloudSQLDatabase">
              <CButton color="primary" @click="verifyCloudSQLDatabase()" style="margin: -3px 0px 0px 5px">
                Continue
              </CButton>
              <div style="text-align: center; margin-top: 15px">

                <div v-if="isRefreshingCloudSQLDatabases" class="spinner-border spinner-border-sm text-primary" role="status">
                  <span class="sr-only"></span>
                </div>
                <div v-else-if="cloudSQLDatabases.length == 0">
                  No databases to display
                </div>
                <div v-else>
                  <div v-for="database in cloudSQLDatabases">
                    <CButton color="secondary" @click="selectCloudSQLDatabase(database)" style="width: 100%; margin-top: 5px">
                      {{ database }}
                    </CButton>
                  </div>
                </div>
              </div>
            </div>
            <!-- ----------------------------------------------------------------- -->
            <div v-else>
              <table v-if="githubURL == null" cellpadding="5">
                <tr>
                  <td><b>Service</b></td>
                  <td><i>{{ serviceName }}</i></td>
                  <td>
                    <CButton v-if="!isCreatingService" @click="enteredServiceName = false">
                      <CIcon name="cil-pencil" />
                    </CButton>
                  </td>
                </tr>
                <tr>
                  <td><b>CloudSQL Connection</b></td>
                  <td><i>{{ cloudSQLConnection }}</i></td>
                  <td>
                    <CButton v-if="!isCreatingService" @click="enteredCloudSQLConnection = false">
                      <CIcon name="cil-pencil" />
                    </CButton>
                  </td>
                </tr>
                <tr>
                  <td><b>CloudSQL Database</b></td>
                  <td><i>{{ cloudSQLDatabase }}</i></td>
                  <td>
                    <CButton v-if="!isCreatingService" @click="enteredCloudSQLDatabase = false">
                      <CIcon name="cil-pencil" />
                    </CButton>
                  </td>
                </tr>
              </table>

              <div v-if="isCreatingService" style="text-align: center">
                <div class="spinner-border spinner-border-sm text-primary" role="status" style="margin-top: 10px">
                  <span class="sr-only"></span>
                </div>
              </div>
              <div v-else-if="githubURL != null">
                <div class="alert alert-success" role="alert" style="margin: 0px 0px 10px 0px">
                  Service was successfully created
                </div>
                <CButton @click="openURL(githubURL)" color="info" style="width: 100%; margin-top: 5px">
                  <CIcon name="cib-github" style="margin-right: 2px; margin-top: -1px" /> View the service on GitHub
                </CButton>
              </div>
              <div v-else>
                <CButton color="primary" @click="createService()" style="margin-top: 10px; width: 100%">
                  Create Service
                </CButton>
              </div>

            </div>


          </div>

          <!-- ----------------------------------------------------------------- -->
          <div v-if="errorMessage">
            <div class="alert alert-danger" role="alert" style="margin: 15px 0px 0px 0px">{{ errorMessage }}</div>
          </div>

        </div>
      </CTab>
      <CTab title="Source code">
        <div class="tab-box" style="padding: 20px">

          <CButton @click="openURL('https://github.com/BSP-Mosaic/teltech-platform.robocalls.ai/tree/main/template/source')" color="info" style="width: 100%; margin-top: 5px">
            <CIcon name="cib-github" style="margin-right: 2px; margin-top: -1px" /> View the template on GitHub
          </CButton>

        </div>
      </CTab>
    </CTabs>

  </div>
</div>
</template>

<script>
export default {
  name: 'Service-Template',
  data() {
    return {
      cloudSQLConnection: null,
      cloudSQLDatabase: null,
      cloudSQLDatabases: [],
      cloudSQLInstances: [],
      enteredCloudSQLConnection: false,
      enteredCloudSQLDatabase: false,
      enteredServiceName: false,
      errorMessage: null,
      githubURL: null,
      isCreatingService: false,
      isRefreshingCloudSQLDatabases: false,
      isRefreshingCloudSQLInstances: false,
      serviceName: null
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    createService() {
      // Make sure we're not currently creating the service
      if (this.isCreatingService) {
        return;
      } else {
        this.errorMessage = null;
        this.isCreatingService = true;
      }

      var that = this;
      var method = "POST";
      var body = JSON.stringify({
        name: this.serviceName,
        cloudsql_connection: this.cloudSQLConnection,
        cloudsql_database: this.cloudSQLDatabase
      });
      var url = "https://admin.platform.robocalls.ai/services/create";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, body, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isCreatingService = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processCreateService(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isCreatingService = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isCreatingService = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    openURL(url) {
      window.open(url, '_blank');
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processCloudSQLDatabases(response) {
      // Make sure we have data
      if (!response || !response.databases) {
        this.errorMessage = "No databases were returned";
        this.cloudSQLDatabases = [];
      } else {
        this.cloudSQLDatabases = response.databases;
      }
    },
    //--------------------------------------------------------------------------
    processCloudSQLInstances(response) {
      // Make sure we have data
      if (!response || !response.instances) {
        this.errorMessage = "No instances were returned";
        this.cloudSQLInstances = [];
        return;
      }

      // Parse the instances
      var instances = [];
      response.instances.forEach((instance) => {
        if (instance.connection) {
          instances.push(instance);
        }
      });

      this.cloudSQLInstances = instances;
    },
    //--------------------------------------------------------------------------
    processCreateService(response) {
      // Make sure we have data
      if (!response || !response.github_url) {
        this.githubURL = "https://github.com/BSP-Mosaic/teltech-platform.robocalls.ai/branches";
      } else {
        this.githubURL = response.github_url;
      }
    },
    //--------------------------------------------------------------------------
    refreshCloudSQLDatabases() {
      // Make sure we have an instance
      if (!this.cloudSQLConnection) {
        return;
      }
      var connection = this.cloudSQLConnection;
      var instanceName = null;
      var self = this;
      this.cloudSQLInstances.forEach((instance) => {
        if (instance.connection == connection) {
          instanceName = instance.name;
        }
      });
      if (!instanceName.length) {
        return;
      }

      // Make sure we're not currently refreshing
      if (this.isRefreshingCloudSQLDatabases) {
        return;
      } else {
        this.isRefreshingCloudSQLDatabases = true;
      }

      // Fetch the cloud-sql database
      var that = this;
      var method = "GET";
      var url = "https://admin.platform.robocalls.ai/cloud-sql/databases?instance=" + encodeURIComponent(instanceName);
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshingCloudSQLDatabases = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processCloudSQLDatabases(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshingCloudSQLDatabases = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshingCloudSQLDatabases = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    refreshCloudSQLInstances() {
      // Make sure we're not currently refreshing
      if (this.isRefreshingCloudSQLInstances) {
        return;
      } else {
        this.isRefreshingCloudSQLInstances = true;
      }

      // Fetch the cloud-sql instances
      var that = this;
      var method = "GET";
      var url = "https://admin.platform.robocalls.ai/cloud-sql/instances";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshingCloudSQLInstances = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processCloudSQLInstances(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshingCloudSQLInstances = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshingCloudSQLInstances = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    scrollToSmoothly(pos, time) {
      var currentPos = window.pageYOffset;
      var start = null;
      if (time == null) time = 500;
      pos = +pos, time = +time;
      window.requestAnimationFrame(function step(currentTime) {
        start = !start ? currentTime : start;
        var progress = currentTime - start;
        if (currentPos < pos) {
          window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
        } else {
          window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
        }
        if (progress < time) {
          window.requestAnimationFrame(step);
        } else {
          window.scrollTo(0, pos);
        }
      });
    },
    //--------------------------------------------------------------------------
    selectCloudSQLConnection(instance) {
      this.cloudSQLConnection = instance.connection;
      this.scrollToSmoothly(0, 300);
    },
    //--------------------------------------------------------------------------
    selectCloudSQLDatabase(database) {
      this.cloudSQLDatabase = database;
      this.scrollToSmoothly(0, 300);
    },
    //--------------------------------------------------------------------------
    typedCloudSQLConnection(event) {
      this.errorMessage = null;
      if (event.keyCode === 13) {
        event.preventDefault();
        this.verifyCloudSQLConnection();
      }
    },
    //--------------------------------------------------------------------------
    typedCloudSQLDatabase(event) {
      this.errorMessage = null;
      if (event.keyCode === 13) {
        event.preventDefault();
        this.verifyCloudSQLDatabase();
      }
    },
    //--------------------------------------------------------------------------
    typedServiceName(event) {
      this.errorMessage = null;
      if (event.keyCode === 13) {
        event.preventDefault();
        this.verifyServiceName();
      }
    },
    //--------------------------------------------------------------------------
    verifyCloudSQLConnection() {
      this.errorMessage = null;
      this.enteredCloudSQLConnection = true;
      this.refreshCloudSQLDatabases();
    },
    //--------------------------------------------------------------------------
    verifyCloudSQLDatabase() {
      this.errorMessage = null;
      this.enteredCloudSQLDatabase = true;
    },
    //--------------------------------------------------------------------------
    verifyServiceName() {
      this.errorMessage = null;

      // Trim whitespace
      this.serviceName = this.serviceName.trim();

      // Convert to lowercase
      this.serviceName = this.serviceName.toLowerCase();

      // Confirm the length of the name is valid
      if (this.serviceName.length == 0) {
        this.errorMessage = "You have to enter a name for your service";
        return;
      } else if (this.serviceName.length > 50) {
        this.errorMessage = "Service name cannot exceed 50 characters";
        return;
      }

      // Confirm the name only contains letters and the dash character
      let regexLettersDash = new RegExp(/[^a-z-]/gi);
      if (this.serviceName != this.serviceName.replace(regexLettersDash, '')) {
        this.errorMessage = "Service name can only contain letters and the dash character";
        return;
      }

      // Confirm the name starts and ends with a letter

      // Confirm the name doesn't contain consecutive dashes

      // Confirm the name doesn't include the word service
      if (this.serviceName.includes("service")) {
        this.errorMessage = "Service name cannot include the word 'service'";
        return;
      }

      this.enteredServiceName = true;
    }
  },
  mounted() {
    this.refreshCloudSQLInstances();
  }
}
</script>
